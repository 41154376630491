import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const Search = ({ name, value, placeholder, onChange, onReset }) => {
  const handleChange = (e) => {
    e.persist();
    onChange(e.target.value);
  };

  return (
    <div className={ styles.search }>
      <input
        name={ name }
        value={ value || '' }
        onChange={ handleChange }
        className={ styles.input }
        placeholder={ placeholder }
        autoComplete="off"
      />
      {/* <div className={ styles.get('search', value && 'active') } /> */}
      { value && <div className={ styles.close } onClick={ onReset } /> }
    </div>
  );
};

Search.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onReset: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

Search.defaultProps = {
  value: null,
};

export default Search;
