/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const InputIconGroup = ({ name, type, icon, label, value, error, style, placeholder, isNumeric, onChange }) => {
  const handleEdit = (e) => {
    e.persist();

    const str = e.target.value;

    if (!isNumeric || !str.length) {
      onChange(e);
    } else {
      const regExp = new RegExp(/^([0-9]+)?\.?([0-9]+)?$/);
      if (!regExp.test(str)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      } else {
        onChange(e);
      }
    }
  };

  return (
    <div className={ styles.get('input-group') } style={ style }>
      { label && <div className={ styles.get('label') }>{ label }</div> }
      <div className={ styles.row }>
        <div className={ styles.get('icon', icon) } />
        <input
          type={ type }
          name={ name }
          value={ value || '' }
          onChange={ handleEdit }
          className={ styles.get('input', error && 'error') }
          placeholder={ placeholder }
        />
      </div>
    </div>
  );
};

InputIconGroup.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.bool,
  style: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  isNumeric: PropTypes.bool,
  placeholder: PropTypes.string,
};

InputIconGroup.defaultProps = {
  type: 'text',
  label: null,
  value: null,
  error: false,
  style: {},
  isNumeric: false,
  placeholder: null,
};

export default InputIconGroup;
