import React, { useState, useRef, memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';
import { useOutsideClick } from 'hooks';
import { loggedUserSelector } from 'store/selectors';

import { Search, Modal } from './components';

import styles from './styles.scss';

extendStyles(styles);

const SelectSupplier = (props) => {
  const { name, label, selected, error, loggedUser, options, style, disabled, placeholder, onChange } = props;

  const [search, setSearch] = useState(null);
  const [focused, setFocused] = useState(false);
  const [modalState, setModalState] = useState({ open: false });
  const [isCollapsed, setIsCollapsed] = useState(true);
  const btnRef = useRef();
  const modalRef = useRef();

  useOutsideClick([btnRef, modalRef], () => {
    setIsCollapsed(true);
    setSearch(null);
  });

  const onToggle = () => {
    if (disabled) return;
    setIsCollapsed((c) => !c);
  };

  const onFocus = () => setFocused(true);

  const onBlur = () => setFocused(false);

  const handleChange = (value) => {
    if (disabled) return;
    setSearch(value);
  };

  const onSelectOption = (value) => {
    setIsCollapsed(true);
    onChange(name, value);
    setSearch(null);
  };

  const handleReset = () => {
    setIsCollapsed(true);
    onChange(name, null);
    setSearch(null);
  };

  const filterOptions = (opts) => {
    if (!search) return opts;
    return opts.filter((o) => o.value.toLowerCase().includes(search.toLowerCase()));
  };

  const renderOption = (item) => {
    const { id, value } = item;

    return (
      <div key={ id } className={ styles.option } onClick={ () => onSelectOption(id) }>
        { value }
      </div>
    );
  };

  const onAddNewSupplier = () => setModalState({ open: true });

  const onCancel = () => setModalState({ open: false });

  return (
    <div className={ styles.select } onMouseEnter={ onFocus } onMouseLeave={ onBlur } style={ style }>
      { label && <div className={ styles.get('label') }>{ label }</div> }
      <div
        ref={ btnRef }
        style={ style }
        className={ styles.get('filter', focused && 'focused', error && 'error') }
      >
        <Search
          name="search"
          value={ search || selected }
          placeholder={ placeholder }
          onReset={ handleReset }
          onChange={ handleChange }
        />
        <div className={ styles.get('icon', focused && 'focused') } onClick={ onToggle }>
          <div className={ styles.arrow } />
        </div>
      </div>
      { loggedUser && (!isCollapsed || search) && (
        <div ref={ modalRef } className={ styles.get('modal', label && 'with-label') } style={ style }>
          <div className={ styles.button } onClick={ onAddNewSupplier }>
            <div className={ styles.new } />
            <div className={ styles.text }>Add New</div>
          </div>
          <div className={ styles.scrollable }>
            { filterOptions(options).map(renderOption) }
          </div>
        </div>
      )}
      { modalState.open && <Modal onCancel={ onCancel } /> }
    </div>
  );
};

SelectSupplier.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  style: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  loggedUser: PropTypes.shape({
    userId: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SelectSupplier.defaultProps = {
  error: false,
  label: null,
  style: {},
  selected: null,
  disabled: false,
  loggedUser: null,
  placeholder: null,
};

export default connect(loggedUserSelector)(memo(SelectSupplier));
