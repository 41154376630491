import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import styles from './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DateControl = ({ date, style, label, maxDate, placeholder, onChange }) => {
  const [diff, setDiff] = useState(null);

  useEffect(() => {
    if (!moment(date).isValid()) return;
    setDiff(moment().diff(moment(date), 'years'));
  }, [date]);

  return (
    <div className={ styles.wrapper } style={ style }>
      { label && (
        <div className={ styles.labelGroup }>
          <div className={ styles.label }>{ label }</div>
          { diff && <div className={ styles.diff }>{ `${diff} yrs` }</div> }
        </div>
      )}
      { maxDate
        ? (
          <DatePicker
            selected={ date ? new Date(moment(date).format('YYYY/MM/DD')) : null }
            maxDate={ maxDate ? new Date(moment(maxDate).format('YYYY/MM/DD')) : null }
            onChange={ onChange }
            className={ styles.datePicker }
            placeholderText={ placeholder }
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            customInput={ <MaskedInput mask="11/11/1111" placeholder={ placeholder } /> }
          />
        )
        : (
          <DatePicker
            selected={ date ? new Date(moment(date).format('YYYY/MM/DD')) : null }
            onChange={ onChange }
            className={ styles.datePicker }
            placeholderText={ placeholder }
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            customInput={ <MaskedInput mask="11/11/1111" placeholder={ placeholder } /> }
          />
        )}
    </div>
  );
};

DateControl.propTypes = {
  date: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.shape({}),
  maxDate: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

DateControl.defaultProps = {
  date: null,
  style: {},
  label: null,
  maxDate: null,
};

export default DateControl;
