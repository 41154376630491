/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const CheckYesNoGroup = ({ name, label, value, style, onChange }) => (
  <div className={ styles.get('check-group') } style={ style }>
    { label && <div className={ styles.get('label') }>{ label }</div> }
    <div className={ styles.get('checkboxes') }>
      <input
        type="radio"
        id={ `${name}-yes` }
        name={ name }
        checked={ value !== null && value === true }
        onChange={ () => onChange(name, true) }
        className={ styles.get('input') }
      />
      <div
        className={ styles.get('checkbox', value !== null && value === true ? 'active' : 'default') }
        onClick={ () => onChange(name, true) }
      />
      <label htmlFor={ `${name}-yes` } className={ styles.label }>Yes</label>
      <input
        type="radio"
        id={ `${name}-no` }
        name={ name }
        checked={ value !== null && value === false }
        onChange={ () => onChange(name, false) }
        className={ styles.get('input') }
      />
      <div
        className={ styles.get('checkbox', value !== null && value === false ? 'active' : 'default') }
        onClick={ () => onChange(name, false) }
      />
      <label htmlFor={ `${name}-no` } className={ styles.label }>No</label>
    </div>
  </div>
);

CheckYesNoGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  style: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
};

CheckYesNoGroup.defaultProps = {
  label: null,
  value: null,
  style: {},
};

export default CheckYesNoGroup;
