import { v4 as uuidv4 } from 'uuid';

export const IS_PROD = process.env.TARGET_ENV === 'production';
const PORT = IS_PROD ? 3000 : 3001;

export const wsUrl = `wss://cspapi.fsportal.site:${PORT}`;
export const apiUrl = `https://cspapi.fsportal.site:${PORT}`;
export const infusionApiUrl = `https://${IS_PROD ? '' : 'staging.'}portal.fsportal.site/infusion-api/api.php`;
export const infusionAuthUrl = `https://signin.infusionsoft.com/app/oauth/authorize?client_id=Ox4GqK5mfigD67vp0q70AwM4QG0gGMe4&redirect_uri=https%3A%2F%2F${IS_PROD ? '' : 'staging.'}portal.fsportal.site%2Finfusion-api%2Fapi.php%3Faction%3Dauthorised&response_type=code&scope=full`;

export const WS_CLIENT_ID = uuidv4();
