/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const Checkbox = ({ name, label, checked, onChange }) => (
  <div className={ styles.inline }>
    <div
      className={ styles.get('checkbox', checked !== null && checked === true ? 'active' : 'default') }
      onClick={ () => onChange(name) }
    />
    <div className={ styles.get('icon', name) } />
    <label htmlFor={ name } className={ styles.label }>{ label }</label>
  </div>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
