import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { extendStyles } from 'helpers';

import styles from '../../styles.scss';

extendStyles(styles);

const Date = ({ type, guest }) => {
  const dateString = type === 'arrival' ? guest._ARRIVALDATE : guest._DEPARTUREDATE;
  let timeString = ((type === 'arrival' ? guest._ARRIVALTIME : guest._DEPARTURETIME) || '').toUpperCase();
  if (/\d(AM|PM)/.test(timeString)) {
    timeString = timeString.replace('AM', ' AM').replace('PM', ' PM');
  }
  const parsedDate = moment(dateString).format('ddd');
  const result = `${parsedDate} ${timeString}`;

  return (
    <td className={ styles.get('cell', 'date') }>
      { result }
    </td>
  );
};

Date.propTypes = {
  type: PropTypes.string.isRequired,
  guest: PropTypes.shape({}).isRequired,
};

export default memo(Date);
