import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Select } from 'components/_common/FormControls';
import adminHandler from 'store/effects/admin/handlers';
import infusionHandler from 'store/effects/infusion/handlers';
import { extendStyles } from 'helpers';
import { infusionAuthUrl } from 'settings';
import { infusionSessionsSelector, infusionGuestsSelector } from 'store/selectors';

import { Tabs } from './components';
import { prepareSelect } from './helpers';
import { Rooms, Travel } from './views';

import styles from './styles.scss';

extendStyles(styles);

const TABS = {
  ROOMS: 'rooms',
  TRAVEL: 'travel',
};

const tabs = [
  { id: 'rooms', label: 'ROOMS', FilterComponent: Rooms },
  { id: 'travel', label: 'TRAVEL INFO', FilterComponent: Travel },
];

const App = () => {
  const [sessionId, setSessionId] = useState(null);
  const [activeTab, setActiveTab] = useState(TABS.ROOMS);
  const [isFetched, setIsFetched] = useState(false);
  const [isAuthorised, setIsAuthorised] = useState(false);

  const { infusionGuests } = useSelector(infusionGuestsSelector);
  const { infusionSessions } = useSelector(infusionSessionsSelector);

  useEffect(() => {
    adminHandler.fetchOceanResortTimeSettings();
    adminHandler.fetchOceanResortNotificationSettings();
    infusionHandler.handleGetInfusionSessions((done) => {
      setIsFetched(true);
      setIsAuthorised(done);
    });
    infusionHandler.handleGetInfusionRooms();
  }, []);

  useEffect(() => {
    if (!infusionSessions || !sessionId || !infusionSessions[sessionId]) return;
    const session = infusionSessions[sessionId];
    infusionHandler.handleGetSessionClients(session.name);
  }, [sessionId, infusionSessions]);

  const onTabSelect = useCallback((tabId) => {
    setActiveTab(tabId);
  }, []);

  const onSessionChange = (_, value) => setSessionId(value);

  const sessionGuests = useMemo(() => {
    if (!infusionSessions || !infusionGuests || !sessionId || !infusionSessions[sessionId]) return null;
    const session = infusionSessions[sessionId];
    return Object.values(infusionGuests).filter(({ _PROGRAMDATESINTERNALUSE }) => _PROGRAMDATESINTERNALUSE === session.name).length;
  }, [infusionGuests, infusionSessions, sessionId]);

  const View = tabs.find(({ id }) => id === activeTab).FilterComponent;

  return (
    <div className={ styles.container }>
      <div className={ styles.heading }>
        Rooms Report for Ocean Resort
      </div>
      <div className={ styles.nav }>
        <div className={ styles.label }>Total Guests: <span>{ sessionGuests }</span></div>
        <div className={ styles.get('label', 'session') }>{ sessionId && infusionSessions[sessionId].name }</div>
        { isFetched && !isAuthorised && (
          <a
            href={ infusionAuthUrl }
            rel="noopener noreferrer"
            target="_blank"
            className={ styles.auth }
          >
            Authorise
          </a>
        )}
        { isFetched && isAuthorised && (
          <Select
            name="session"
            selected={ sessionId ? infusionSessions[sessionId].name : null }
            options={ prepareSelect({ data: infusionSessions, field: 'name', filterCol: 'id' }) }
            onChange={ onSessionChange }
            style={ { width: 250, margin: 0 } }
            placeholder="Select Session to import"
          />
        )}
      </div>
      <Tabs tabs={ tabs } activeTab={ activeTab } onTabSelect={ onTabSelect } />
      <View sessionId={ sessionId } sessionName={ infusionSessions[sessionId]?.name } />
    </div>
  );
};

export default App;
