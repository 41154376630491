import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import { Message } from 'components/_common';
import infusionHandler from 'store/effects/infusion/handlers';
import { extendStyles } from 'helpers';
import { EditableSelect } from 'components/_common/FormControls';
import { infusionSessionsSelector, infusionGuestsSelector, oceanResortSettingsSelector, optionsSelector } from 'store/selectors';

import { COLS } from './cols';
import { prepareSelect } from './helpers';

import styles from './styles.scss';

extendStyles(styles);

const Rooms = ({ sessionId, sessionName }) => {
  const [order, setOrder] = useState({ field: 'firstName', order: 1 });
  const [action, setAction] = useState({ done: false, error: false });
  const [editMode, setEditMode] = useState(false);
  const [guestRooms, setGuestRooms] = useState({});

  const { options } = useSelector(optionsSelector);
  const { infusionGuests } = useSelector(infusionGuestsSelector);
  const { infusionSessions } = useSelector(infusionSessionsSelector);
  const { oceanResortSettings } = useSelector(oceanResortSettingsSelector);

  const sessionGuests = useMemo(() => {
    if (!infusionSessions || !infusionGuests || !sessionId || !infusionSessions[sessionId]) return null;
    const session = infusionSessions[sessionId];
    return Object.values(infusionGuests).filter(({ _PROGRAMDATESINTERNALUSE }) => _PROGRAMDATESINTERNALUSE === session.name);
  }, [infusionGuests, infusionSessions, sessionId]);

  useEffect(() => {
    const data = sessionGuests?.reduce((res, { Id, _ROOMNUMBER0 }) => ({
      ...res,
      [Id]: { originalRoom: _ROOMNUMBER0, room: _ROOMNUMBER0 },
    }), {});
    
    setGuestRooms(data);
  }, [sessionGuests]);

  const onOrdering = (id, canOrder) => {
    if (!canOrder) return;
    setOrder((o) => ({ field: id, order: o.field === id ? -o.order : 1 }));
  };

  const orderData = (data) => {
    if (!order.field) return data;

    if (order.field === 'firstName') {
      return data.sort((a, b) => {
        const fieldA = a.FirstName;
        const fieldB = b.FirstName;
        if (fieldA < fieldB) return -1 * order.order;
        if (fieldA > fieldB) return 1 * order.order;
        return 0;
      });
    }

    if (order.field === 'lastName') {
      return data.sort((a, b) => {
        const fieldA = a.LastName;
        const fieldB = b.LastName;
        if (fieldA < fieldB) return -1 * order.order;
        if (fieldA > fieldB) return 1 * order.order;
        return 0;
      });
    }

    if (order.field === 'cycle') {
      return data.sort((a, b) => {
        const fieldA = a._PROGRAMCYCLESINTERNALUSE;
        const fieldB = b._PROGRAMCYCLESINTERNALUSE;
        if (fieldA < fieldB) return -1 * order.order;
        if (fieldA > fieldB) return 1 * order.order;
        return 0;
      });
    }

    if (order.field === 'dates') {
      return data.sort((a, b) => {
        const fieldA = a._SESSIONSTARTDATE;
        const fieldB = b._SESSIONSTARTDATE;
        if (fieldA < fieldB) return -1 * order.order;
        if (fieldA > fieldB) return 1 * order.order;
        return 0;
      });
    }

    if (order.field === 'room') {
      return data.sort((a, b) => {
        const fieldA = a._ROOMNUMBER0;
        const fieldB = b._ROOMNUMBER0;
        if (fieldA < fieldB) return -1 * order.order;
        if (fieldA > fieldB) return 1 * order.order;
        return 0;
      });
    }

    if (order.field === 'area') {
      return data.sort((a, b) => {
        const fieldA = a._AreaoffocusPhysicalEmotionalHabits;
        const fieldB = b._AreaoffocusPhysicalEmotionalHabits;
        if (fieldA < fieldB) return -1 * order.order;
        if (fieldA > fieldB) return 1 * order.order;
        return 0;
      });
    }
  };

  const onRoomEdit = useCallback((item) => {
    setGuestRooms((prev) => ({
      ...prev,
      [item.id]: { ...prev[item.id], room: item.value },
    }));
  }, []);

  const renderHeaderCols = (col) => {
    const { id, title, canOrder } = col;

    return (
      <th
        key={ id }
        className={ styles.get('cell', id) }
        onClick={ () => onOrdering(id, canOrder) }
      >
        { title }
        { order.field === id && <div className={ styles.get('order', order.order === 1 ? 'asc' : 'desc') } /> }
      </th>
    );
  };

  const renderCell = (cell, item) => {
    if (cell.id === 'room' && editMode) {
      return (
        <td key={ cell.id } className={ styles.get('cell', cell.id, 'edit') }>
          <EditableSelect
            id={ item.Id }
            name="room"
            value={ guestRooms[item.Id].room }
            options={ prepareSelect({ data: options, field: 'infusionRooms' }) }
            onEdit={ onRoomEdit }
          />
        </td>
      );
    }

    return (
      <td key={ cell.id } className={ styles.get('cell', cell.id) }>
        {item[cell.lookupField]}
      </td>
    );
  };

  const renderRow = (data) => (
    <tr key={ data.Id } className={ styles.row }>
      { COLS.map((cell) => renderCell(cell, data)) }
    </tr>
  );

  const editModeEnable = useCallback(() => {
    setEditMode(true);
  }, []);

  const editModeDisable = useCallback(() => {
    setEditMode(false);
  }, []);

  const handleSave = () => {
    const data = Object
      .entries(guestRooms)
      .filter(([_, { room, originalRoom }]) => room !== originalRoom)
      .map(([id, { room }]) => ({ id: +id, roomNumber: room }));
    if (data.length) {
      infusionHandler.handleUpdateRooms({ data, action: 'updateRooms' }).then(({ data: { status } }) => {
        if (status === 'error') setAction({ error: true, done: false });
        else if (status === 'done') {
          infusionHandler.handleGetSessionClients(sessionName);
          setAction({ error: false, done: true });
          setEditMode(false);
        }
      });
    } else {
      setEditMode(false);
    }
  };

  const getClientName = (id) => {
    const client = sessionGuests.find(({ Id }) => Id === id);
    return `${client.FirstName} ${client.LastName}`;
  };

  const handleSaveNotify = () => {
    const { emails } = oceanResortSettings.notifications
    const data = Object
      .entries(guestRooms)
      .filter(([_, { room, originalRoom }]) => room !== originalRoom)
      .map(([id, { room, originalRoom }]) => ({ id: +id, clientName: getClientName(+id), roomNumber: room, originalRoom }));
    if (data.length) {
      infusionHandler.handleUpdateRoomsAndNotify({ data, action: 'updateRoomsNotify', emails }).then(({ data: { status } }) => {
        if (status === 'error') setAction({ error: true, done: false });
        else if (status === 'done') {
          infusionHandler.handleGetSessionClients(sessionName);
          setAction({ error: false, done: true });
          setEditMode(false);
        }
      });
    } else {
      setEditMode(false);
    }
  };

  const handleCloseMessage = () => setAction({ error: false, done: false });

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.inline }>
        {editMode && <div className={ styles.label}>Edit Mode Enabled</div>}
        <div className={ styles.button } onClick={ editModeEnable }>Edit Room #</div>
      </div>
      <table className={ styles.table }>
        <thead className={ styles.header }>
          <tr className={ styles.header }>
            { COLS.map(renderHeaderCols) }
          </tr>
        </thead>
        <tbody>
          { orderData(sessionGuests || [])?.map(renderRow) }
        </tbody>
      </table>
      <div className={ styles.inline }>
        <div className={ styles.get('button', 'save') } onClick={ handleSave }>Save</div>
        <div className={ styles.get('button', 'save') } onClick={ handleSaveNotify }>Save & Notify Fresh Start</div>
        <div className={ styles.get('button', 'cancel') } onClick={ editModeDisable }>Cancel</div>
      </div>
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
        open={ action.error }
        autoHideDuration={ 2000 }
        onClose={ () => handleCloseMessage() }
      >
        <Message message="Error" variant="error" onClose={ () => handleCloseMessage() } />
      </Snackbar>
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
        open={ action.done }
        autoHideDuration={ 2000 }
        onClose={ () => handleCloseMessage() }
      >
        <Message message="Rooms have been updated" variant="success" onClose={ () => handleCloseMessage() } />
      </Snackbar>
    </div>
  );
};

Rooms.propTypes = {
  sessionId: PropTypes.number,
  sessionName: PropTypes.string,
};

Rooms.defaultProps = {
  sessionId: null,
  sessionName: null,
}

export default Rooms;
