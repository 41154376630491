import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import supplierHandler from 'store/effects/customProtocols/suppliers/handlers';
import { extendStyles } from 'helpers';

import { loggedUserSelector } from 'store/selectors';

import { InputGroup } from 'components/_common/FormControls';

import { Checkbox, InputIconGroup } from './components';

import styles from './styles.scss';

extendStyles(styles);

const Modal = ({ loggedUser, onCancel }) => {
  const [formData, setFormData] = useState({
    businessName: { value: null, error: false },
    manufacturer: { value: null },
    wholesaler: { value: null },
    online: { value: null },
    local: { value: null },
    website: { value: null },
    phone: { value: null },
  });

  const onChange = (e) => {
    e.persist();

    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const notNull = formData[fieldName].notNull !== undefined ? formData[fieldName].notNull : false;
    setFormData((fd) => ({
      ...fd,
      [fieldName]: {
        ...fd[fieldName],
        value: fieldValue,
        error: notNull ? !fieldValue.length : false,
      },
    }));
  };

  const handleCheckbox = (field) => setFormData((fd) => ({
    ...fd,
    [field]: { value: !fd[field].value },
  }));

  const onSave = () => {
    if (!loggedUser.userId) return;

    if (!formData.businessName.value || !formData.businessName.value.length) {
      setFormData((fd) => ({ ...fd, businessName: { ...fd.businessName, error: true } }));
    }

    supplierHandler.handleSupplierCreate({
      businessName: formData.businessName.value,
      businessSince: null,
      timezone: null,
      manufacturer: formData.manufacturer.value,
      wholesaler: formData.wholesaler.value,
      online: formData.online.value,
      local: formData.local.value,
      website: formData.website.value,
      phone: formData.phone.value,
      image: null,
      street1: null,
      street2: null,
      city: null,
      provinceState: null,
      postalCode: null,
      country: null,
      googleMap: null,
      account: null,
      paymentType: null,
      paymentTerms: null,
      deliverDays: null,
      minOrder: null,
      preferredShipper: null,
      quickOrder: null,
      catalogue: null,
      createdAt: new Date(),
      createdBy: loggedUser.userId,
    }, () => onCancel());
  };

  return (
    <div className={ styles.overlay }>
      <div className={ styles.modal }>
        <div className={ styles.inner }>
          <div className={ styles.close } onClick={ onCancel } />
          <div className={ styles.heading }>New Supplier Record</div>
          <div className={ styles.separator } />
          <div className={ styles.row }>
            <InputGroup
              name="businessName"
              label="BUSINESS NAME *"
              value={ formData.businessName.value }
              error={ formData.businessName.error }
              style={ { width: 325, marginRight: 20 } }
              onChange={ onChange }
            />
            <InputIconGroup
              name="website"
              label="WEBSITE"
              icon="website"
              value={ formData.website.value }
              style={ { width: 325 } }
              placeholder="Business Website"
              onChange={ onChange }
            />
          </div>
          <div className={ styles.row }>
            <div className={ styles.column }>
              <div className={ styles.get('label') }>SUPPLIER TYPE (Select All that apply)</div>
              <div className={ styles.container }>
                <div className={ styles.box }>
                  <Checkbox
                    name="manufacturer"
                    label="MANUFACTURER"
                    style={ { marginRight: 20 } }
                    checked={ formData.manufacturer.value }
                    onChange={ handleCheckbox }
                  />
                  <Checkbox
                    name="local"
                    label="LOCAL"
                    style={ { marginRight: 20 } }
                    checked={ formData.local.value }
                    onChange={ handleCheckbox }
                  />
                </div>
                <div className={ styles.box }>
                  <Checkbox
                    name="wholesaler"
                    label="WHOLESALER"
                    style={ { marginRight: 20 } }
                    checked={ formData.wholesaler.value }
                    onChange={ handleCheckbox }
                  />
                  <Checkbox
                    name="online"
                    label="ONLINE"
                    style={ { marginRight: 20 } }
                    checked={ formData.online.value }
                    onChange={ handleCheckbox }
                  />
                </div>
              </div>
            </div>
            <InputIconGroup
              name="phone"
              label="BUSINESS PHONE"
              icon="phone"
              value={ formData.phone.value }
              style={ { width: 325 } }
              placeholder="Business Phone"
              onChange={ onChange }
            />
          </div>
          <div className={ styles.get('separator', 'green') } />
          <div className={ styles.buttonGroup }>
            <div className={ styles.get('button', 'yes', 'confirmed') } onClick={ onSave }>Save</div>
            <div className={ styles.get('button', 'no') } onClick={ onCancel }>Cancel</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  loggedUser: PropTypes.shape({
    userId: PropTypes.number,
  }),
};

Modal.defaultProps = {
  loggedUser: null,
};

export default connect(loggedUserSelector)(Modal);
