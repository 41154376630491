import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import ReactQuill from 'react-quill';
import { extendStyles } from 'helpers';

import 'react-quill/dist/quill.snow.css';
import styles from './styles.scss';

extendStyles(styles);

const RichTextEditor = ({ name, label, value, style, autoFocus, onChange, onDblClick, isDisabled }) => {
  const ref = useRef();

  const handleQuill = (data) => onChange(data, name);

  useEffect(() => {
    if (!autoFocus) return;
    ref.current.focus();
  }, [autoFocus]);

  return (
    <div className={ styles.quillWrapper } onDoubleClick={ onDblClick }>
      <div className={ styles.get('label') }>{ label }</div>
      <div className={ styles.get('quill', isDisabled && 'disabled') }>
        <ReactQuill
          ref={ ref }
          theme="snow"
          value={ value }
          style={ style }
          onChange={ handleQuill }
          readOnly={ isDisabled }
        />
      </div>
    </div>
  );
};

RichTextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.number,
    minHeight: PropTypes.number,
  }),
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onDblClick: PropTypes.func,
};

RichTextEditor.defaultProps = {
  value: null,
  style: {},
  onChange: () => {},
  autoFocus: false,
  isDisabled: false,
  onDblClick: () => {},
};

export default RichTextEditor;
