import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import DatePicker from 'react-datepicker';

import styles from './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DateGroup = ({ name, date, label, minDate, maxDate, placeholder, style, onChange }) => {
  const handleChange = (e) => {
    onChange(name, e);
  };

  return (
    <div className={ styles.wrapper } style={ style }>
      { label && (
        <div className={ styles.labelGroup }>
          <div className={ styles.label }>{ label }</div>
        </div>
      )}
      { maxDate
        ? (
          <DatePicker
            selected={ date ? new Date(moment(date).format('YYYY/MM/DD')) : null }
            minDate={ minDate ? new Date(moment(minDate).format('YYYY/MM/DD')) : null }
            maxDate={ maxDate ? new Date(moment(maxDate).format('YYYY/MM/DD')) : null }
            onChange={ handleChange }
            className={ styles.datePicker }
            placeholderText={ placeholder }
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        )
        : (
          <DatePicker
            selected={ date ? new Date(moment(date).format('YYYY/MM/DD')) : null }
            onChange={ handleChange }
            className={ styles.datePicker }
            placeholderText={ placeholder }
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        )}
    </div>
  );
};

DateGroup.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.shape({}),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

DateGroup.defaultProps = {
  date: null,
  label: null,
  style: {},
  minDate: null,
  maxDate: null,
};

export default DateGroup;
