import React, { useState, memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { extendStyles } from 'helpers';
import { loggedUserSelector } from 'store/selectors';

import { DateControl } from '..';

import styles from './styles.scss';

extendStyles(styles);

const EditableDate = ({ id, name, value, minDate, maxDate, format, inlineEdit, loggedUser, onEdit }) => {
  const [isEdit, setIsEdit] = useState(false);

  const onToggleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEdit((s) => !s);
  };

  const onSave = (val) => {
    setIsEdit(false);
    const item = {
      id,
      field: name,
      value: val,
      updatedAt: new Date(),
      updatedBy: loggedUser.userId };
    onEdit(item);
  };

  const renderDate = () => (isEdit
    ? (
      <DateControl
        date={ value }
        style={ { width: 130, marginRight: 0, marginBottom: 0 } }
        minDate={ minDate }
        maxDate={ maxDate }
        onChange={ onSave }
        placeholder="mm/dd/yyyy"
      />
    )
    : <div className={ styles.text } onClick={ onToggleEdit }>{ value ? moment(value).format(format) : 'N/A' }</div>);

  const renderText = () => <div>{ value ? moment(value).format(format) : 'N/A' }</div>;

  if (!loggedUser) return null;

  return (inlineEdit ? renderDate() : renderText());
};

EditableDate.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  format: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  loggedUser: PropTypes.shape({
    userId: PropTypes.number,
  }),
  inlineEdit: PropTypes.bool.isRequired,
};

EditableDate.defaultProps = {
  id: null,
  value: null,
  minDate: null,
  maxDate: null,
  loggedUser: null,
};

export default connect(loggedUserSelector)(memo(EditableDate));
