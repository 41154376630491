import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import styles from './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DateTimeGroup = ({ name, date, label, maxDate, placeholder, timeIntervals, style, onChange }) => {
  const handleChange = (e) => {
    onChange(name, e);
  };

  return (
    <div className={ styles.wrapper } style={ style }>
      <div className={ styles.labelGroup }>
        <div className={ styles.label }>{ label }</div>
      </div>
      { maxDate
        ? (
          <DatePicker
            selected={ date ? new Date(moment(date).format('YYYY/MM/DD HH:mm:ss')) : null }
            maxDate={ maxDate }
            onChange={ handleChange }
            className={ styles.datePicker }
            placeholderText={ placeholder }
            showTimeSelect
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            timeFormat="HH:mm"
            timeIntervals={ timeIntervals }
            timeCaption="Time"
          />
        )
        : (
          <DatePicker
            selected={ date ? new Date(moment(date).format('YYYY/MM/DD HH:mm:ss')) : null }
            onChange={ handleChange }
            className={ styles.datePicker }
            placeholderText={ placeholder }
            showTimeSelect
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            timeFormat="HH:mm"
            timeIntervals={ timeIntervals }
            timeCaption="Time"
            dateFormat="MM/dd/yyyy hh:mm aa"
          />
        )}
    </div>
  );
};

DateTimeGroup.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string,
  label: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  maxDate: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string.isRequired,
  timeIntervals: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

DateTimeGroup.defaultProps = {
  date: null,
  style: {},
  maxDate: null,
  timeIntervals: 15,
};

export default DateTimeGroup;
