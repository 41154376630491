import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const TextGroup = ({ name, label, value, error, style, onChange, disabled }) => (
  <div className={ styles.get('input-group') } style={ style }>
    <div className={ styles.get('label') }>{ label }</div>
    <textarea
      name={ name }
      value={ value || '' }
      disabled={ disabled }
      onChange={ onChange }
      className={ styles.get('textarea', error && 'error', disabled && 'disabled') }
    />
  </div>
);

TextGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.bool,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

TextGroup.defaultProps = {
  value: null,
  error: false,
  style: {},
  disabled: false,
};

export default TextGroup;
