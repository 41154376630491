import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';
import { useOutsideClick } from 'hooks';

import { Search } from './components';

import styles from './styles.scss';

extendStyles(styles);

const Select = ({ style, value, options, onChange, isFixed }) => {
  const [search, setSearch] = useState(null);
  const [focused, setFocused] = useState(false);
  const [styleCss, setStyleCss] = useState({});
  const [isCollapsed, setCollapsed] = useState(true);
  const selRef = useRef();
  const modalRef = useRef();

  useEffect(() => {
    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('scroll', updatePosition);
    };
  }, []);

  useEffect(() => {
    updatePosition();
  }, [selRef]);

  useOutsideClick([selRef, modalRef], () => {
    setCollapsed(true);
    onChange(value);
  });

  const updatePosition = useCallback(() => {
    if (!selRef.current) return;
    const { x, y, width } = selRef.current.getBoundingClientRect();
    if (isFixed) {
      setStyleCss({ position: 'fixed', top: y + 40, left: x, width });
    } else {
      setStyleCss({ position: 'absolute', top: 40, left: 0, width });
    }
  }, [isFixed]);

  const onToggle = () => setCollapsed((c) => !c);

  const onFocus = () => setFocused(true);

  const onBlur = () => setFocused(false);

  const onSelectOption = (option) => {
    setCollapsed(true);
    onChange(option);
  };

  const handleChange = (val) => setSearch(val);

  const renderOption = (item) => {
    const { id, value: label } = item;

    return (
      <div key={ id } className={ styles.option } onClick={ () => onSelectOption(id) }>
        { label }
      </div>
    );
  };

  const filterOptions = (opts) => {
    if (!search) return opts;
    return opts.filter((o) => o.value.toLowerCase().includes(search.toLowerCase()));
  };

  return (
    <div className={ styles.select } onMouseEnter={ onFocus } onMouseLeave={ onBlur }>
      <div
        id="sel_text"
        ref={ selRef }
        onClick={ onToggle }
        className={ styles.get('filter', focused && 'focused') }
      >
        <div className={ styles.selected }>{ value }</div>
        <div className={ styles.icon }>
          <div className={ styles.arrow } />
        </div>
      </div>
      { !isCollapsed && (
        <div ref={ modalRef } className={ styles.modal } style={ { ...style, ...styleCss } }>
          <Search name="search" value={ search } onChange={ handleChange } />
          <div className={ styles.scrollable }>
            { filterOptions(options).map(renderOption) }
          </div>
        </div>
      )}
      { isCollapsed && <div ref={ modalRef } /> }
    </div>
  );
};

Select.propTypes = {
  style: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })).isRequired,
  isFixed: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  style: {},
  isFixed: true,
};

export default Select;
