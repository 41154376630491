import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const placeholderImage = 'https://cdn.fsportal.site/csp/images/icons/placeholder-image.svg';

const Dropzone = ({ disabled, previewUrl, onFilesAdded }) => {
  const [hightlight, setHightlight] = useState(false);
  const fileInputRef = createRef();

  const openFileDialog = () => {
    if (disabled) return;
    fileInputRef.current.click();
  };

  const onChange = (event) => {
    if (disabled) return;
    const file = event.target.files.item(0);
    onFilesAdded(file);
  };

  const onDragOver = (evt) => {
    evt.preventDefault();
    if (disabled) return;
    setHightlight(true);
  };

  const onDragLeave = () => {
    setHightlight(false);
  };

  const onDrop = (evt) => {
    evt.preventDefault();
    if (disabled) return;
    const file = evt.dataTransfer.files.item(0);
    onFilesAdded(file);
    setHightlight(false);
  };

  return (
    <div
      className={ styles.get('dropzone', hightlight && 'highlight') }
      onDragOver={ onDragOver }
      onDragLeave={ onDragLeave }
      onDrop={ onDrop }
      onClick={ openFileDialog }
      style={ { cursor: disabled ? 'default' : 'pointer' } }
    >
      <input
        ref={ fileInputRef }
        type="file"
        name="image"
        accept=".png, .gif, .jpeg, .svg"
        onChange={ onChange }
        className={ styles.fileInput }
      />
      <img
        alt="upload"
        className={ styles.get('icon', previewUrl && 'avatar') }
        src={ previewUrl || placeholderImage }
      />
      { !previewUrl && <div className={ styles.label }>Select or Drag Image</div> }
    </div>
  );
};

Dropzone.propTypes = {
  disabled: PropTypes.bool.isRequired,
  previewUrl: PropTypes.string,
  onFilesAdded: PropTypes.func.isRequired,
};

Dropzone.defaultProps = {
  previewUrl: null,
};

export default Dropzone;
