import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';
import { loggedUserSelector } from 'store/selectors';

import { Select } from './components';

import styles from './styles.scss';

extendStyles(styles);

const EditableSelect = ({ id, name, style, value, route, options, onEdit, loggedUser, isFixed }) => {
  const [isEdit, setIsEdit] = useState(true);
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => setFieldValue(value), [value]);

  const onChange = (val) => {
    setFieldValue(val);

    if (fieldValue !== val) {
      const item = { id, field: name, value: val, updatedAt: new Date(), updatedBy: loggedUser.userId };
      onEdit(item);
    }
  };

  const displayValue = value === null ? 'N/A' : value;

  return isEdit
    ? (
      <Select
        style={ style }
        value={ fieldValue || 'N/A' }
        options={ options }
        isFixed={ isFixed }
        onChange={ onChange }
      />
    )
    : <div className={ route && styles.text }>{ displayValue }</div>;

};

EditableSelect.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  route: PropTypes.string,
  style: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  onEdit: PropTypes.func.isRequired,
  loggedUser: PropTypes.shape({
    userId: PropTypes.number,
  }),
  isFixed: PropTypes.bool,
};

EditableSelect.defaultProps = {
  route: null,
  style: {},
  value: null,
  isFixed: true,
  loggedUser: null,
};

export default connect(loggedUserSelector)(memo(EditableSelect));
