import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import optionHandler from 'store/effects/admin/handlers';
import { extendStyles } from 'helpers';
import { useOutsideClick } from 'hooks';

import { InputGroup } from 'components/_common/FormControls';
import { Search } from './components';

import styles from './styles.scss';

extendStyles(styles);

const MultiSelect = (props) => {
  const {
    name,
    label,
    error,
    style,
    value,
    option,
    options,
    disabled,
    onChange,
    autoFocus,
    placeholder,
  } = props;

  const [search, setSearch] = useState(null);
  const [focused, setFocused] = useState(false);
  const [modalState, setModalState] = useState({ open: false });
  const [isCollapsed, setIsCollapsed] = useState(true);
  const btnRef = useRef();
  const modalRef = useRef();

  useEffect(() => {
    if (!autoFocus) return;
    setFocused(true);
  }, [autoFocus]);

  useOutsideClick([btnRef, modalRef], () => {
    setSearch(null);
    setFocused(false);
    setIsCollapsed(true);
  });

  const onToggle = () => {
    if (disabled) return;
    setFocused(isCollapsed);
    setIsCollapsed((c) => !c);
  };

  const handleFocus = (val) => setFocused(val);

  const handleChange = (val) => {
    if (disabled) return;
    setSearch(val);
  };

  const handleChangeInput = (e) => {
    e.persist();

    const fieldValue = e.target.value;

    setModalState((fd) => ({
      ...fd,
      field: {
        value: fieldValue,
        error: !fieldValue.length,
      },
    }));
  };

  const onSelectOption = (id, optionLabel) => {
    const selectedOptions = value || [];
    const wasSelected = selectedOptions.find((o) => +o.id === +id);
    const data = wasSelected
      ? selectedOptions.filter((s) => +s.id !== +id)
      : [...selectedOptions, { id, value: optionLabel }];
    onChange(name, data);
    setSearch(null);
    setFocused(false);
  };

  const handleReset = () => {
    onChange(name, null);
    setSearch(null);
    setIsCollapsed(true);
  };

  const filterOptions = (opts) => {
    if (!search) return opts;
    return opts.filter((o) => o.value.toLowerCase().includes(search.toLowerCase()));
  };

  const isSelected = (data, id) => data.find((o) => +o.id === +id);

  const renderOption = (item) => {
    const { id, value: optionLabel } = item;
    const selectedOptions = value || [];

    return (
      <div
        key={ id }
        className={ styles.get('option', isSelected(selectedOptions, id) && 'selected') }
        onClick={ () => onSelectOption(id, optionLabel) }
      >
        { optionLabel }
      </div>
    );
  };

  const onAddNewToggle = () => setModalState({
    open: true,
    field: { value: null, error: false },
  });

  const onSave = () => {
    if (modalState.field.value && modalState.field.value.length) {
      optionHandler.addOption({
        category: option.category,
        tableName: option.tableName,
        option: modalState.field.value,
      }, () => setModalState({ open: false }));
    }
  };

  const onCancel = () => setModalState({ open: false });

  const getSelectedValue = () => {
    if (search) return search;
    if (!value) return null;
    if (value.length === 1) return value[0].value;
    if (value.length > 1) return 'Multiple';
    return null;
  };

  const toTitleCase = (str) => str
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

  return (
    <div className={ styles.select } style={ style }>
      { label && <div className={ styles.get('label') }>{ label }</div> }
      <div
        ref={ btnRef }
        style={ style }
        className={ styles.get('filter', focused && 'focused', error && 'error') }
      >
        <Search
          name="search"
          value={ getSelectedValue() }
          focused={ focused }
          onReset={ handleReset }
          onFocus={ handleFocus }
          onChange={ handleChange }
          placeholder={ placeholder }
        />
        <div className={ styles.get('icon', focused && 'focused') } onClick={ onToggle }>
          <div className={ styles.arrow } />
        </div>
      </div>
      { (!isCollapsed || search) && (
        <div ref={ modalRef } className={ styles.get('modal', label && 'with-label') } style={ style }>
          { option && !modalState.open && !search && (
          <div className={ styles.button } onClick={ onAddNewToggle }>
            <div className={ styles.new } />
            <div className={ styles.text }>Add New</div>
          </div>
          )}
          { !modalState.open
            ? (
              <div className={ styles.scrollable }>
                { filterOptions(options).map(renderOption) }
              </div>
            )
            : (
              <div className={ styles.form }>
                <div className={ styles.title }>{ `New ${toTitleCase(label)}` }</div>
                <InputGroup
                  name="field"
                  label={ label }
                  value={ modalState.field.value }
                  error={ modalState.field.error }
                  style={ { width: '100%' } }
                  onChange={ handleChangeInput }
                />
                <div className={ styles.buttonGroup }>
                  <div className={ styles.get('btn', 'accept') } onClick={ onSave }>Save</div>
                  <div className={ styles.get('btn', 'cancel') } onClick={ onCancel }>Cancel</div>
                </div>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  style: PropTypes.shape({}),
  value: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
  })),
  option: PropTypes.shape({ category: PropTypes.string, tableName: PropTypes.string }),
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

MultiSelect.defaultProps = {
  error: false,
  label: null,
  style: {},
  option: null,
  value: null,
  disabled: false,
  autoFocus: false,
  placeholder: null,
};

export default MultiSelect;
