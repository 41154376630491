import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';
import InputMask from 'react-input-mask';

import styles from './styles.scss';

extendStyles(styles);

const MaskedInputGroup = ({ name, mask, label, value, error, style, onChange }) => (
  <div className={ styles.get('input-group') } style={ style }>
    <div className={ styles.get('label') }>{ label }</div>
    <InputMask
      value={ value || '' }
      mask={ mask }
      onChange={ (e) => onChange(name, e) }
      className={ styles.get('input', error && 'error') }
    />
  </div>
);

MaskedInputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.bool.isRequired,
  style: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
};

MaskedInputGroup.defaultProps = {
  label: null,
  value: null,
  style: {},
};

export default MaskedInputGroup;
