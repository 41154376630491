import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from '../../styles.scss';

extendStyles(styles);

const Transport = ({ type, guest }) => (
  <td className={ styles.get('cell', 'transport') }>
    { type === 'arrival' ? guest._ARRIVALTRANSPORT : guest._DEPARTURETRANSPORT }
  </td>
);

Transport.propTypes = {
  type: PropTypes.string.isRequired,
  guest: PropTypes.shape({}).isRequired,
};

export default memo(Transport);
