import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const LabelText = ({ name, type, value, placeholder, onChange }) => (
  <div className={ styles.wrapper }>
    <div className={ styles.get('icon', type) } />
    <input
      type="text"
      name={ name }
      value={ value || '' }
      onChange={ onChange }
      className={ styles.input }
      placeholder={ placeholder }
    />
  </div>
);

LabelText.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

LabelText.defaultProps = {
  value: null,
};

export default LabelText;
