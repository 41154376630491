import { updateOceanResortSettings } from './actions';

export default {
  [updateOceanResortSettings]: (state, { payload }) => ({
    ...state,
    oceanResortSettings: {
      ...state.oceanResortSettings,
      ...payload,
    },
  }),
};
