export const COLS = [
  {
    id: 'name',
    title: 'Name',
    canOrder: true,
  },
  {
    id: 'date',
    title: 'Date',
    canOrder: true,
  },
  {
    id: 'transport',
    title: 'Transpport',
    canOrder: true,
  },
  {
    id: 'carrier',
    title: 'Carrier',
    canOrder: true,
  },
];
