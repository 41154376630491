/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const CurrencyText = ({ name, label, value, error, style, placeholder, onChange, isDisabled }) => {
  const handleEdit = (fieldValue, fieldName) => onChange(fieldName, fieldValue);

  return (
    <div className={ styles.get('input-group') } style={ style }>
      { label && <div className={ styles.get('label') }>{ label }</div> }
      <CurrencyInput
        name={ name }
        value={ value || '' }
        prefix="$"
        className={ styles.get('input', error && 'error') }
        placeholder={ placeholder }
        onValueChange={ handleEdit }
        disabled={ isDisabled }
      />
    </div>
  );
};

CurrencyText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.bool.isRequired,
  style: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
};

CurrencyText.defaultProps = {
  label: null,
  value: null,
  style: {},
  placeholder: null,
  isDisabled: false,
};

export default CurrencyText;
