import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { infusionSessionsSelector, infusionGuestsSelector } from 'store/selectors';

import { Card } from './components';

import styles from './styles.scss';

const TravelInfo = ({ sessionId }) => {
  const { infusionGuests } = useSelector(infusionGuestsSelector);
  const { infusionSessions } = useSelector(infusionSessionsSelector);

  const sessionGuests = useMemo(() => {
    if (!infusionSessions || !infusionGuests || !sessionId || !infusionSessions[sessionId]) return null;
    const session = infusionSessions[sessionId];
    return Object.values(infusionGuests).filter(({ _PROGRAMDATESINTERNALUSE }) => _PROGRAMDATESINTERNALUSE === session.name);
  }, [infusionGuests, infusionSessions, sessionId]);

  return (
    <div className={ styles.wrapper }>
      <Card sessionId={ sessionId } guests={ sessionGuests } type="arrival" />
      <Card sessionId={ sessionId } guests={ sessionGuests } type="departure" />
    </div>
  );
};

TravelInfo.propTypes = {
  sessionId: PropTypes.number,
};

TravelInfo.defaultProps = {
  sessionId: null,
}

export default TravelInfo;
