import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from '../../styles.scss';

extendStyles(styles);

const Name = ({ guest }) => (
  <td className={ styles.get('cell', 'name') }>
    { `${guest.FirstName} ${guest.LastName}` }
  </td>
);

Name.propTypes = {
  guest: PropTypes.shape({
    LastName: PropTypes.string,
    FirstName: PropTypes.string,
  }).isRequired,
};

export default memo(Name);
