import { useState } from 'react';
import moment from 'moment';

export default function useOrder({ type, guests }) {
  const [order, setOrder] = useState({ field: 'name', order: 1 });

  const onOrdering = (id, canOrder) => {
    if (!canOrder) return;
    setOrder((o) => ({ field: id, order: o.field === id ? -o.order : 1 }));
  };

  const orderData = (data) => {
    if (!order.field || !guests) return data;

    if (order.field === 'name') {
      return data.sort((a, b) => {
        const guestA = `${a.FirstName} ${a.LastName}`;
        const guestB = `${b.FirstName} ${b.LastName}`;
        if (guestA < guestB) return -1 * order.order;
        if (guestA > guestB) return 1 * order.order;
        return 0;
      });
    }
    if (order.field === 'date') {
      return data.sort((a, b) => {
        const field = type === 'arrival' ? '_ARRIVALDATE' : '_DEPARTUREDATE';
        const dateA = moment(a[field]);
        const dateB = moment(b[field]);
        if (dateA.isBefore(dateB)) return -1 * order.order;
        if (dateA.isAfter(dateB)) return 1 * order.order;
        return 0;
      });
    }
    if (order.field === 'transport') {
      return data.sort((a, b) => {
        const field = type === 'arrival' ? '_ARRIVALTRANSPORT' : '_DEPARTURETRANSPORT';
        const transportA = a[field];
        const transportB = b[field];
        if (transportA < transportB) return -1 * order.order;
        if (transportA > transportB) return 1 * order.order;
        return 0;
      });
    }
    if (order.field === 'carrier') {
      return data.sort((a, b) => {
        const field = type === 'arrival' ? '_ARRIVALCARRIER0' : '_DEPARTURECARRIER0';
        const carrierA = a[field];
        const carrierB = b[field];
        if (carrierA < carrierB) return -1 * order.order;
        if (carrierA > carrierB) return 1 * order.order;
        return 0;
      });
    }
  };

  return { order, orderData, onOrdering };
}
