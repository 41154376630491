/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const InputText = ({ name, type, value, error, style, placeholder, isNumeric, isDisabled, onChange, onReset }) => {
  const handleEdit = (e) => {
    e.persist();

    const str = e.target.value;

    if (!isNumeric || !str.length) {
      onChange(e);
    } else {
      const regExp = new RegExp(/^-?([0-9]+)?\.?([0-9]+)?$/);
      if (!regExp.test(str)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      } else {
        onChange(e);
      }
    }
  };

  const inputStyle = style.backgroundColor ? {
    backgroundColor: style.backgroundColor,
  } : {};

  return (
    <div className={ styles.get('input-group') } style={ style }>
      <input
        type={ type }
        name={ name }
        value={ value || '' }
        style={ inputStyle }
        onChange={ handleEdit }
        className={ styles.get('input', error && 'error') }
        placeholder={ placeholder }
        disabled={ isDisabled }
        autoComplete="off"
      />
      { (value && onReset) ? <div className={ styles.close } onClick={ onReset } /> : null }
    </div>
  );
};

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.bool,
  style: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
  onReset: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  isNumeric: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

InputText.defaultProps = {
  type: 'text',
  error: false,
  value: null,
  style: {},
  onReset: null,
  isNumeric: false,
  isDisabled: false,
  placeholder: null,
};

export default InputText;
