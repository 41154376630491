import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';
import { oceanResortSettingsSelector } from 'store/selectors';

import { COLS } from './cols';
import { Name, Date, Transport, Carrier } from './components';

import styles from './styles.scss';

extendStyles(styles);

const Table = ({ type, table, order, orderData, onOrdering, listOptions }) => {
  const [showTable, setShowTable] = useState(true);

  const { oceanResortSettings } = useSelector(oceanResortSettingsSelector);

  const renderHeaderCols = (col) => {
    const { id, title, canOrder } = col;

    return (
      <th
        key={ id }
        className={ styles.get('cell', id) }
        onClick={ () => onOrdering(id, canOrder) }
      >
        { title }
        { order.field === id && <div className={ styles.get('order', order.order === 1 ? 'asc' : 'desc') } /> }
      </th>
    );
  };

  const renderCell = (id, guest) => {
    if (id === 'name') return <Name key={ id } guest={ guest } />;
    if (id === 'date') return <Date key={ id } type={ table } guest={ guest } />;
    if (id === 'transport') return <Transport key={ id } type={ table } guest={ guest } />;
    if (id === 'carrier') return <Carrier key={ id } type={ table } guest={ guest } />;
    return <td key={ id } className={ styles.get('cell', id) } />;
  };

  const renderGuest = (item, index) => (
    <tr key={ item.Id } className={ styles.get('row', index % 2 === 1 && 'odd') }>
      { COLS.map((c) => renderCell(c.id, item)) }
    </tr>
  );

  const onToggleTable = useCallback(() => {
    setShowTable((prev) => !prev);
  }, []);

  const items = useMemo(() => orderData(listOptions.filtered)
    .filter((gs) => {
      if (type === 'all') return true;
      if (table === 'arrival') return gs.arrivalType === type;
      return gs.departureType === type;
    }), [listOptions.filtered, type, orderData]);

  const title = useMemo(() => {
    const departureSettings = Object.values(oceanResortSettings || {}).find(({ type }) => type === 'departure');
    if (table === 'departure' && !departureSettings) return null;

    if (type === 'all') {
      return (
        <div className={ styles.label }>
          <b>All Guests</b>{ `(${items.length})` }
        </div>
      );
    }
    if (type === 'early') {
      return (
        <div className={ styles.label }>
          <b>Early {table === 'arrival' ? 'Arrivals' : 'Departures'}</b>{ `(${items.length}) - ${table === 'arrival' ? '2 days prior to 1:00 PM' : `${departureSettings.earlyLabel} ${departureSettings.earlyTime}`}` }
        </div>
      );
    }
    if (type === 'regular') {
      return (
        <div className={ styles.label }>
          <b>Regular {table === 'arrival' ? 'Arrivals' : 'Departures'}</b>{ `(${items.length}) - ${table === 'arrival' ? '1:01 PM - 4:30 PM' : `${departureSettings.regularLabel} ${departureSettings.regularTime}`}` }
        </div>
      );
    }
    if (type === 'late') {
      return (
        <div className={ styles.label }>
          <b>Late {table === 'arrival' ? 'Arrivals' : 'Departures'}</b>{ `(${items.length}) - ${table === 'arrival' ? '4:31 PM - 12 AM or next day' : `${departureSettings.lateLabel} ${departureSettings.lateTime}`}` }
        </div>
      );
    }
    return (
      <div className={ styles.label }>
        <b>CUSTOM</b>{ `(${items.length})` }
      </div>
    );
  }, [type, items, oceanResortSettings]);

  return (
    <div className={ styles.section }>
      <div className={ styles.get('heading', type === 'all' && 'left') }>
        { title }
        { type !== 'all' && (
          <div className={ styles.button } onClick={ onToggleTable }>View</div>
        )}
      </div>
      {showTable && (
        <table className={ styles.table }>
          <thead>
            <tr className={ styles.get('header') }>
              { COLS.map(renderHeaderCols) }
            </tr>
          </thead>
          <tbody>
            { items.map(renderGuest) }
          </tbody>
        </table>
      )}
    </div>
  );
};

Table.propTypes = {
  type: PropTypes.string.isRequired,
  table: PropTypes.string.isRequired,
  order: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.number,
  }).isRequired,
  orderData: PropTypes.func.isRequired,
  onOrdering: PropTypes.func.isRequired,
  listOptions: PropTypes.shape({}).isRequired,
};

export default Table;
