/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const CurrencyInput = ({ name, label, value, error, style, placeholder, onChange }) => {
  const handleEdit = (e) => {
    e.persist();

    const str = e.target.value;

    if (!str.length) onChange(e);
    else {
      const regExp = new RegExp(/^([0-9]+)?\.?([0-9]+)?$/);
      if (!regExp.test(str)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      } else onChange(e);
    }
  };

  return (
    <div className={ styles.get('input-group') } style={ style }>
      { label && <div className={ styles.get('label') }>{ label }</div> }
      <input
        type="number"
        name={ name }
        value={ value || '' }
        onChange={ handleEdit }
        className={ styles.get('input', error && 'error') }
        placeholder={ placeholder }
      />
    </div>
  );
};

CurrencyInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.bool.isRequired,
  style: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

CurrencyInput.defaultProps = {
  label: null,
  value: null,
  style: {},
  placeholder: null,
};

export default CurrencyInput;
