import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';
import { loggedUserSelector } from 'store/selectors';

import { Input } from './components';

import styles from './styles.scss';

extendStyles(styles);

const EditableText = ({ name, route, id, value, inlineEdit, loggedUser, onEdit }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => setFieldValue(value), [value]);

  const onToggleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEdit((s) => !s);
  };

  const onChange = (val) => setFieldValue(val);

  const onReset = () => setFieldValue(value);

  const onSave = () => {
    setIsEdit(false);
    if (fieldValue !== value) {
      const item = {
        id,
        field: name,
        value: fieldValue,
        updatedAt: new Date(),
        updatedBy: loggedUser.userId };
      onEdit(item);
    }
  };

  const renderTextOrInput = () => {
    const displayValue = value === null ? 'N/A' : value;

    return isEdit
      ? (
        <Input
          value={ fieldValue }
          onChange={ onChange }
          onReset={ onReset }
          onSave={ onSave }
        />
      )
      : <div className={ route && styles.text } onClick={ onToggleEdit }>{ displayValue }</div>;
  };

  const renderLinkOrMailto = () => (name === 'email'
    ? <a className={ styles.link } href={ route }>{ value }</a>
    : <NavLink className={ styles.link } to={ route }>{ value }</NavLink>);

  const renderTextOrLink = () => {
    if (route) return renderLinkOrMailto();
    const displayValue = value === null ? 'N/A' : value;
    return <div>{ displayValue }</div>;
  };

  if (!loggedUser) return null;

  return (inlineEdit ? renderTextOrInput() : renderTextOrLink());
};

EditableText.propTypes = {
  name: PropTypes.string.isRequired,
  route: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number,
  inlineEdit: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  loggedUser: PropTypes.shape({
    userId: PropTypes.number,
  }),
};

EditableText.defaultProps = {
  route: null,
  id: null,
  value: null,
  loggedUser: null,
};

export default connect(loggedUserSelector)(memo(EditableText));
