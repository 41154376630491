import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { oceanResortSettingsSelector } from 'store/selectors';

const MONTHS = {
  'Jan': '01',
  'Feb': '02',
  'Mar': '03',
  'Apr': '04',
  'May': '05',
  'Jun': '06',
  'Jul': '07',
  'Aug': '08',
  'Sep': '09',
  'Oct': '10',
  'Nov': '11',
  'Dec': '12',
};

const useListOptions = ({ guests, sessionId, infusionSessions, reportWeek }) => {
  const [sessionDays, setSessionDays] = useState(null);
  const [totalGuests, setTotalGuests] = useState(0);
  const [listOptions, setListOptions] = useState({
    filtered: [],
  });

  const { oceanResortSettings } = useSelector(oceanResortSettingsSelector);

  useEffect(() => {
    if (!infusionSessions || !sessionId || !infusionSessions[sessionId] || !guests.length) return;

    const session = infusionSessions[sessionId];
    let startDate = null;
    let endDate = null;

    if (/\w{3}\s\d+-\w{3}\s\d+\s\d{4}/.test(session.name)) {
      const chunks = session.name.split('-');
      const strStartMonth = chunks[0].split(' ')[0];
      const startDay = chunks[0].split(' ')[1];
      const strEndMonth = chunks[1].split(' ')[0];
      const endDay = chunks[1].split(' ')[1];
      const year = chunks[1].split(' ')[2];
      const startMonth = MONTHS[strStartMonth];
      const endMonth = MONTHS[strEndMonth];
      startDate = `${year}-${startMonth}-${startDay}`;
      endDate = `${year}-${endMonth}-${endDay}`;
    } else if (/\w{3}\s\d+-\d+\s\d{4}/.test(session.name)) {
      const chunks = session.name.split('-');
      const strMonth = chunks[0].split(' ')[0];
      const startDay = chunks[0].split(' ')[1];
      const endDay = chunks[1].split(' ')[0];
      const year = chunks[1].split(' ')[1];
      const month = MONTHS[strMonth];
      startDate = `${year}-${month}-${startDay}`;
      endDate = `${year}-${month}-${endDay}`;
    }

    const diff = moment(endDate).diff(moment(startDate), 'days') + 1;
    setSessionDays(diff);
  }, [
    guests,
    sessionId,
    infusionSessions,]);

  useEffect(() => {
    if (!infusionSessions || !sessionId || !infusionSessions[sessionId] || !guests.length || !oceanResortSettings) return;
    
    const departureSettings = Object.values(oceanResortSettings).find(({ type }) => type === 'departure');
    if (!departureSettings) return;

    const checkWeek = (guest) => {
      if (reportWeek === 'All') return true;

      const cycle = guest._PROGRAMCYCLESINTERNALUSE;
      if (!cycle) return false;

      let sessionWeeks = [];
      const cycleRegex = /^(\d+)\sDAY\s?(I+)?$/;
      
      if (cycleRegex.test(cycle.toUpperCase())) {
        const matches = cycle.toUpperCase().match(cycleRegex);
        const days = +matches[1];
        const weeks = parseInt(days / 7, 10) + (days % 7 > 0 ? 1 : 0);
        const startingWeek = matches[2] ? (matches[2].match(/I/g) || []).length : 1;
        sessionWeeks = [...Array(weeks).keys()].map((weekNo) => `W${startingWeek + weekNo}`);
      } else if (cycle === 'CUSTOM') {
        const session = infusionSessions[sessionId];
        let startDate = null;
        let endDate = null;

        if (/\w{3}\s\d+-\w{3}\s\d+\s\d{4}/.test(session.name)) {
          const chunks = session.name.split('-');
          const strStartMonth = chunks[0].split(' ')[0];
          const startDay = chunks[0].split(' ')[1];
          const strEndMonth = chunks[1].split(' ')[0];
          const endDay = chunks[1].split(' ')[1];
          const year = chunks[1].split(' ')[2];
          const startMonth = MONTHS[strStartMonth];
          const endMonth = MONTHS[strEndMonth];
          startDate = `${year}-${startMonth}-${startDay}`;
          endDate = `${year}-${endMonth}-${endDay}`;
        } else if (/\w{3}\s\d+-\d+\s\d{4}/.test(session.name)) {
          const chunks = session.name.split('-');
          const strMonth = chunks[0].split(' ')[0];
          const startDay = chunks[0].split(' ')[1];
          const endDay = chunks[1].split(' ')[0];
          const year = chunks[1].split(' ')[1];
          const month = MONTHS[strMonth];
          startDate = `${year}-${month}-${startDay}`;
          endDate = `${year}-${month}-${endDay}`;
        }

        const arrivalDate = guest._ARRIVALDATE;
        const departureDate = guest._DEPARTUREDATE;
        const programStartDate = guest._ProgramStartDate;
        const programFinishDate = guest._ProgramFinishDate;
        
        const customStartDate = programStartDate || arrivalDate;
        const customEndDate = programFinishDate || departureDate;

        if (startDate && endDate && customStartDate && customEndDate) {
          const customStartDiff = moment(customStartDate).diff(moment(startDate), 'days');
          const customStartWeekNo = customStartDiff <= 7 ? 1 : parseInt((customStartDiff - 1) / 7, 10) + 1;
          sessionWeeks = [`W${customStartWeekNo}`];
        }
      }

      return sessionWeeks.includes(reportWeek);
    };

    const data = guests
      .filter((guest) => {
        const filterWeek = checkWeek(guest);
        return filterWeek;
      })
      .map((guest) => {
        const session = infusionSessions[sessionId];
        let startDate = null;

        if (/\w{3}\s\d+-\w{3}\s\d+\s\d{4}/.test(session.name)) {
          const chunks = session.name.split('-');
          const strMonth = chunks[0].split(' ')[0];
          const day = chunks[0].split(' ')[1];
          const year = chunks[1].split(' ').pop();
          const nMonth = MONTHS[strMonth];
          startDate = `${year}-${nMonth}-${day}`;
        } else if (/\w{3}\s\d+-\d+\s\d{4}/.test(session.name)) {
          const chunks = session.name.split('-');
          const strMonth = chunks[0].split(' ')[0];
          const day = chunks[0].split(' ')[1];
          const year = chunks[1].split(' ').pop();
          const nMonth = MONTHS[strMonth];
          startDate = `${year}-${nMonth}-${day}`;
        }

        const arrivalDate = guest._ARRIVALDATE;
        const programStartDate = guest._ProgramStartDate;
        
        const cycle = guest._PROGRAMCYCLESINTERNALUSE;

        let startingWeek = null;
        const cycleRegex = /^(\d+)\sDAY\s?(I+)?$/;

        if (cycleRegex.test(cycle.toUpperCase())) {
          const matches = cycle.toUpperCase().match(cycleRegex);
          startingWeek = matches[2] ? (matches[2].match(/I/g) || []).length : 1;
        } else if (cycle === 'CUSTOM') {
          const customStartDate = programStartDate || arrivalDate;
          if (startDate && customStartDate) {
            const customStartDiff = moment(customStartDate).diff(moment(startDate), 'days');
            const customStartWeekNo = customStartDiff <= 7 ? 1 : parseInt((customStartDiff - 1) / 7, 10) + 1;
            startingWeek = customStartWeekNo;
          }
        }

        // Arrival
        const arrivalDateString = guest._ARRIVALDATE;
        let arrivalTimeString = (guest._ARRIVALTIME || '').toUpperCase();
        if (/\d(AM|PM)/.test(arrivalTimeString)) {
          arrivalTimeString = arrivalTimeString.replace('AM', ' AM').replace('PM', ' PM');
        }
        const arrivalDt = `${arrivalDateString} ${arrivalTimeString}`;

        const earlyArrivalStart = moment(`${moment(startDate).add((startingWeek - 1) * 7 - 2, 'days').format('YYYY-MM-DD')} 00:00:00`);
        const earlyArrivalFinish = moment(`${moment(startDate).add((startingWeek - 1) * 7, 'days').format('YYYY-MM-DD')} 13:00:00`);

        const regularArrivalStart = moment(`${moment(startDate).add((startingWeek - 1) * 7, 'days').format('YYYY-MM-DD')} 13:00:00`);
        const regularArrivalFinish = moment(`${moment(startDate).add((startingWeek - 1) * 7, 'days').format('YYYY-MM-DD')} 16:30:00`);

        const lateArrivalStart = moment(`${moment(startDate).add((startingWeek - 1) * 7, 'days').format('YYYY-MM-DD')} 16:30:00`);
        const lateArrivalFinish = moment(`${moment(startDate).add((startingWeek - 1) * 7, 'days').format('YYYY-MM-DD')} 23:59:59`);

        let arrivalType = 'custom';
        if (arrivalTimeString && moment(arrivalDt).isSameOrAfter(earlyArrivalStart) && moment(arrivalDt).isSameOrBefore(earlyArrivalFinish)) arrivalType = 'early';
        else if (arrivalTimeString && moment(arrivalDt).isAfter(regularArrivalStart) && moment(arrivalDt).isSameOrBefore(regularArrivalFinish)) arrivalType = 'regular';
        else if (arrivalTimeString && moment(arrivalDt).isAfter(lateArrivalStart) && moment(arrivalDt).isSameOrBefore(lateArrivalFinish)) arrivalType = 'late';

        // Departure
        const departureDateString = guest._DEPARTUREDATE;
        let departureTimeString = (guest._DEPARTURETIME || '').toUpperCase();
        if (/\d(AM|PM)/.test(departureTimeString)) {
          departureTimeString = departureTimeString.replace('AM', ' AM').replace('PM', ' PM');
        }
        const departureDt = `${departureDateString} ${departureTimeString}`;

        const earlyDepartureFinish = moment(`${moment(departureDateString).format('YYYY-MM-DD')} ${departureSettings.earlyTime}:00`);
        const regularDeparture = moment(`${moment(departureDateString).format('YYYY-MM-DD')} ${departureSettings.regularTime}:00`);
        const lateDepartureStart = moment(`${moment(departureDateString).format('YYYY-MM-DD')} ${departureSettings.lateTime}:00`);


        let departureType = 'custom';
        if (departureTimeString && moment(departureDt).isBefore(earlyDepartureFinish)) departureType = 'early';
        else if (departureTimeString && moment(departureDt).isSame(regularDeparture)) departureType = 'regular';
        else if (departureTimeString && moment(departureDt).isAfter(lateDepartureStart)) departureType = 'late';

        return { ...guest, arrivalType, departureType };
      });

    setTotalGuests(data.length);
    setListOptions((state) => ({ ...state, filtered: data }));
  }, [
    guests,
    sessionId,
    reportWeek,
    infusionSessions,
    oceanResortSettings,
  ]);

  return {
    sessionDays,
    listOptions,
    totalGuests,
  };
};

export default useListOptions;
