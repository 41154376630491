/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const LabelGroup = ({ label, value, style, isHtml, onDblClick }) => (
  <div className={ styles.get('label-group') } style={ style } onDoubleClick={ onDblClick }>
    <div className={ styles.get('label') }>{ label }</div>
    { isHtml
      ? <div className={ styles.get('value') } dangerouslySetInnerHTML={ { __html: value } } />
      : <div className={ styles.get('value') }>{ value }</div> }
  </div>
);

LabelGroup.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.shape({}),
  isHtml: PropTypes.bool,
  onDblClick: PropTypes.func,
};

LabelGroup.defaultProps = {
  value: null,
  style: {},
  isHtml: false,
  onDblClick: () => {},
};

export default LabelGroup;
