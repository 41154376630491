import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';

import styles from './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const TimeGroup = ({ name, date, label, placeholder, timeIntervals, style, onChange }) => {
  const onTimeChange = (val) => onChange(name, val);

  return (
    <div className={ styles.wrapper } style={ style }>
      <div className={ styles.labelGroup }>
        <div className={ styles.label }>{ label }</div>
      </div>
      <DatePicker
        selected={ date }
        onChange={ onTimeChange }
        className={ styles.datePicker }
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={ timeIntervals }
        dateFormat="h:mm aa"
        placeholderText={ placeholder }
      />
    </div>
  );
};

TimeGroup.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
  label: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  timeIntervals: PropTypes.number,
};

TimeGroup.defaultProps = {
  date: null,
  style: {},
  timeIntervals: 15,
};

export default TimeGroup;
