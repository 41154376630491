import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from '../../styles.scss';

extendStyles(styles);

const Carrier = ({ guest, type }) => (
  <td className={ styles.get('cell', 'carrier') }>
    { type === 'arrival' ? guest._ARRIVALCARRIER0 : guest._DEPARTURECARRIER0 }
  </td>
);

Carrier.propTypes = {
  type: PropTypes.string.isRequired,
  guest: PropTypes.shape({}).isRequired,
};

export default memo(Carrier);
