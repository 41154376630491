import React, { useState, useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { extendStyles } from 'helpers';
import { infusionSessionsSelector } from 'store/selectors';

import { Table } from './components';
import { useListOptions, useOrder } from './hooks';

import styles from './styles.scss';

extendStyles(styles);

const VIEWS = {
  COMBINED: 'combined',
  SEGMENTED: 'segmented',
};

const Card = ({ type, sessionId, guests }) => {
  const [view, setView] = useState(VIEWS.COMBINED);
  const [reportWeek, setReportWeek] = useState('All');

  const { infusionSessions } = useSelector(infusionSessionsSelector);

  const { sessionDays, listOptions } = useListOptions({ guests, sessionId, infusionSessions, reportWeek });
  const { order, orderData, onOrdering } = useOrder({ guests });

  const weeks = useMemo(() => (sessionDays ? parseInt(sessionDays / 7, 10) : null), [sessionDays]);

  const onToggleView = useCallback(() => {
    setView((prev) => (prev === VIEWS.COMBINED ? VIEWS.SEGMENTED : VIEWS.COMBINED));
  }, []);

  const onWeekChange = useCallback((weekNo) => {
    setReportWeek(weekNo);
  }, []);

  const commonProps = {
    order,
    table: type,
    orderData,
    onOrdering,
    listOptions,
  };

  return (
    <div className={ styles.card }>
      <div className={ styles.header }>
        <div className={ styles.heading }>
          {type === 'arrival' && <div className={ styles.arrive } />}
          {type === 'departure' && <div className={ styles.depart } />}
          {`${type === 'arrival' ? 'Arrivals' : 'Departures'} List ${guests?.length ? `(${guests.length})` : ''}`}
        </div>
      </div>
      <div className={ styles.content }>
        <div className={ styles.row }>
          { weeks && (
            <div className={ styles.toggle }>
              <div
                className={ styles.get('list', reportWeek === 'All' && 'active') }
                onClick={ () => onWeekChange('All') }
              >
                All
              </div>
              { Array.from(Array(weeks).keys()).map((weekNo) => (
                <div
                  key={ weekNo }
                  className={ styles.get('list', reportWeek === `W${weekNo + 1}` && 'active') }
                  onClick={ () => onWeekChange(`W${weekNo + 1}`) }
                >
                  { `W${weekNo + 1}` }
                </div>
              ))}
            </div>
          )}
          <div className={ styles.button } onClick={ onToggleView }>{view === VIEWS.SEGMENTED ? 'Show All' : 'Segmented View'}</div>
        </div>
        {view === VIEWS.SEGMENTED
          ? (
            <>
              <Table { ...commonProps } type="early" />
              <Table { ...commonProps } type="regular" />
              <Table { ...commonProps } type="late" />
              <Table { ...commonProps } type="custom" />
            </>
          )
          : (
            <Table { ...commonProps } type="all" />
          )}
      </div>
    </div>
  );
};

Card.propTypes = {
  type: PropTypes.string.isRequired,
  guests: PropTypes.arrayOf(PropTypes.shape({})),
  sessionId: PropTypes.number,
};

Card.defaultProps = {
  guests: [],
  sessionId: null,
};

export default memo(Card);
