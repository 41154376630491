import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const Search = ({ name, value, onChange }) => {
  const [focused, setFocused] = useState(false);

  const handleChange = (e) => {
    e.persist();
    onChange(e.target.value);
  };

  const onClear = () => onChange(null);

  const onFocus = () => setFocused(true);

  const onBlur = () => setFocused(false);

  return (
    <div className={ styles.search } onMouseEnter={ onFocus } onMouseLeave={ onBlur }>
      <input
        name={ name }
        value={ value || '' }
        onChange={ handleChange }
        className={ styles.get('input', focused && 'focused') }
        placeholder="Search"
      />
      <div className={ styles.search } />
      { focused && <div className={ styles.close } onClick={ onClear } /> }
    </div>
  );
};

Search.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Search.defaultProps = {
  value: null,
};

export default Search;
