/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';
import { useOutsideClick } from 'hooks';

import styles from './styles.scss';

extendStyles(styles);

const Input = ({ value, onChange, onReset, onSave }) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();

  useOutsideClick([inputRef], () => onSave());

  const handleChange = (e) => {
    e.persist();
    onChange(e.target.value);
  };

  const handleSubmit = (e) => {
    if (e.key !== 'Enter') return;
    onSave();
  };

  const onClear = () => onReset();

  const onFocus = () => setFocused(true);

  const onBlur = () => setFocused(false);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={ styles.search } onClick={ handleClick } onMouseEnter={ onFocus } onMouseLeave={ onBlur }>
      <input
        ref={ inputRef }
        value={ value || '' }
        onChange={ handleChange }
        onKeyDown={ handleSubmit }
        autoFocus
        className={ styles.get('input', focused && 'focused') }
      />
      { focused && <div className={ styles.close } onClick={ onClear } /> }
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

Input.defaultProps = {
  value: null,
};

export default Input;
