import { parseStartDate } from 'helpers';

export const prepareSelect = ({ data, field, filterCol = null }) => {
  if (!Object.values(data).length) return [];

  const arr = Object.values(data);

  const filtered = filterCol
    ? arr.sort((a, b) => {
      if (a[filterCol] === null) return 1;
      if (b[filterCol] === null) return -1;
      if (a[filterCol] < b[filterCol]) return -1;
      if (a[filterCol] > b[filterCol]) return 1;
      return 0;
    })
    : arr;

  return filtered.map(({ id, [field]: value }) => ({ id: +id, value }));
};
